import axios from 'axios';

const baseURL = process.env.REACT_APP_SANHA_BACKEND_BASEURL;
const apiKey = '$2a$12$Kxl0CW5r2aS.RG6cZzMWpOIirtmUfLLqqxfFi2ShWVcbJqRpU7Tz.';
const vender_id = process.env.REACT_APP_SANHA_BACKEND_VENDERID;
const company_id = process.env.REACT_APP_SANHA_BACKEND_COMPANYID;
const langTypeCode = 'KOR';

// config
const request = axios.create({
  baseURL,
});

const headers = (header: any) => {
  return {
    headers: {
      'api-key': apiKey,
      vendor_id: vender_id,
      company_id: company_id,
      'Content-Type': 'application/json',
      ...(header && header),
    },
  };
};

const querystring = (query: any) => {
  return {
    params: query,
  };
};

//보낼 때, 타입
export interface rsvnNorAndsVnSeqNoProps {
  rsvnNo?: string | null;
  rsvnSeqNo?: string | null;
}
export interface folioNoProps {
  folioNo: string;
}
export interface checkInApiProps extends rsvnNorAndsVnSeqNoProps {
  roomNo: string;
  privacyPolicy?: string;
}
export interface checkOutApiPops extends folioNoProps {
  earlyCheckoutYN: string;
}
export interface registCheckInPaymentApiProps {
  trnsNo: string;
  intApprTypeCode: string;
  intPayStatusCode: number;
  payFnshDate: string;
  successYn: string;
  trnsDate: string;
  trnsHHMMSS: string;
  trnsAmt: string;
  cardMonthCnt: string;
  cardApprNo: string;
  cardName: string;
  cardIssueCode: string;
  cardPurcCode: string;
  trnsBankCode: string;
  acctNo: string;
  depositor: string;
  cashRcptTypeCode: number;
  cashRcptApptNo: string;
  cashRcptCxlApptNo: string;
  responseCode: string;
  message1: string;
  message2: string;
  dpsitPayDivCode: string;
  userId: string;
  userIp: string;
  rsvnNo: string;
  rsvnSeqNo: string;
  payMgtNo: string;
  dpsitPayNo: string;
  outYN: string;
  outMsg: string;
}
export interface registCheckOutPaymentApiProps {
  trnsNo: string;
  intApprTypeCode: string;
  intPayStatusCode: number;
  payFnshDate: string;
  successYn: string;
  trnsDate: string;
  trnsHHMMSS: string;
  trnsAmt: string;
  cardMonthCnt: string;
  cardApprNo: string;
  cardName: string;
  cardIssueCode: string;
  cardPurcCode: string;
  trnsBankCode: string;
  acctNo: string;
  depositor: string;
  cashRcptTypeCode: number;
  cashRcptApptNo: string;
  cashRcptCxlApptNo: string;
  responseCode: string;
  message1: string;
  message2: string;
  folioNo: string;
  userId: string;
  userIp: string;
  dpsitPayDivCode?: string;
  payMgtNo: string;
  dpsitPayNo: string;
  outYN: string;
  outMsg: string;
}

//서버에서 받아오는 타입(response)
export interface serviceListServerApi {
  //산하 서비스 목록 정보
  svcCode: string; //서비스 코드
  svcName: string; //서비스 명
  qty: number; //서비스 수량
  amt: number; //서비스 금액
  totalAmt: number; //서비스 총 금액
}

export interface listBookingServerApi {
  //예약 조회
  propertyName: string; //시설명
  rsvnNo: string; //예약번호
  rsvnSeqNo: string; //예약번호 순번
  rsvnStatusCode: string; //예약 상태 코드 (RR)
  guestName: string; //고객명
  mobileNo: string; //휴대폰 번호
  email: string; //이메일
  arrvDate: string; //입실일자 (20230301)
  arrvPlanTiem: string; //입실 예정 시간 (1010)
  deptDate: string; //퇴실 일자 (20230302)
  deptPlanTiem: string; //퇴실 예정 시간 (1810)
  nights: number; //숙박 일 (1)
  caclPayCode: string; //지불 코드
  roomNo: string; //객실 번호
  roomFee: number; //객실 요금
  roomTypeCode: string; //객실 코드
  roomTypeDesc: string; //객실 명
  folioNo: string; //원장번호
  serviceList: serviceListServerApi[]; //서비스 목록
}
export interface selectCheckedInBookingServerApi {
  //투숙리스트 단건 조회
  propertyName: string;
  rsvnNo: string;
  rsvnSeqNo: string;
  rsvnStatusCode: string;
  guestName: string;
  mobileNo: string;
  email: string;
  arrvDate: string;
  arrvPlanTiem: string;
  deptDate: string;
  deptPlanTiem: string;
  nights: number;
  caclPayCode: string;
  roomNo: string;
  roomFee: number;
  roomTypeCode: string;
  roomTypeDesc: string;
  serviceList: serviceListServerApi[];
}
export interface listAvailableRoomServerApi {
  //사용 가능 객실 조회
  roomNo: string; //객실번호
  roomTypeCode: string; //객실유형 코드
  roomTypeDesc: string; //객실유형 명
  bedTypeCode: string; //침대 유형 코드
  bedTypeName: string; //침대 유형 명
  floorCode: string; //층 코드
  floorName: string; //층 명
  zoneTypeCode: string; //구역 코드
  zoneTypeName: string; //구역 명
  viewCode: string; //전망 코드
  viewName: string; //전망 명
  roomStatus: string; // 룸 상태(VC, VI)

  /* bsnsCode: string, //사업장 코드
  propertyNo: string, //프로퍼티 코드
  propertyName: string, //프로퍼티 명
   */
}
export interface checkInsServerApi {
  //체크인 데이터
  folioNo: string; //원장 번호
  roomNo: string; //객실 번호
  rsvnNo: string; //예약 번호
  rsvnSeqNo: string; //예약 번호 순번
  arrvDate: string | Date; //입실 일자(YYYYMMDD)
  deptDate: string | Date; //퇴실 일자(YYYYMMDD)
  nights: string; //박수
  guestName: string; //투숙객명
  roomTypeCode: string; //객실타입 ("BM31ND")
  roomTypeDesc: string; //객실타입 ("B콘도미지정31평노블더블베드")
  bsnsCode: string; //사업장 코드
  propertyNo: string; //프로퍼티 코드
  propertyName: string; //프로퍼티명
  roomKeyPincode: string; //객실키 - 핀코드
  roomKeyMobileKey: string; //객실키 모바일키 인증번
}
export interface confirmCheckInPaymentServerApi {
  //예약 결제 여부 확인
  payYn: string; //Y: 이력 존재,N: 이력 없음
  reason: string; //payYn가 N 일 때 사유
}
export interface confirmCheckInAmountServerApi {
  //예약 결제 금액 확인
  totalAmount: number; //객실 요금 + 서비스 요금
  roomAmount: number; //객실 요금
  serviceAmount: number; //서비스 요금
}
export interface registCheckInPaymentServerApi {
  //예약 결제 내역 등록
  payMgtNo: string; //지불관리 번호
  dpsitPayNo: string; //선수금 번호
}
export interface confirmCheckOutAmountServerApi {
  //퇴실 잔액 조회
  balanceExist: string; //잔액 존재 여부, Y: 있음, N: 없음, X: 기타 오류
  errorMsg: string; //balanceExist 가 Y일 때 사유
  balanceAmount: number; //잔액 (미지불 금액)
}
export interface registCheckOutPaymentServerApi {
  //퇴실 결제 내역 등록
  payMgtNo: string; //지불번호
  dpsitPayNo: string; //선수금 번호
}

export interface configDataApi {
  pgPaymentYn: string;
  depositUseYn: string;
  depositType: string;
  checkoutPgPayment: string;
}

type ServerState<A> = {
  code: string;
  message: string;
  data: A;
};

//Api 통신 부분
//Booking
export const listBookings = () => {
  const bsnsCode = 15;
  const propertyNo = 15;
  const arrvDateStart = 20230403;
  const arrvDateEnd = 20230403;
  return request.get(`/v1/bookings`, {
    ...headers({}),
    ...querystring({
      bsnsCode,
      propertyNo,
      arrvDateStart,
      arrvDateEnd,
      langTypeCode,
    }),
  });
};

export const listBooking = async ({ rsvnNo, rsvnSeqNo = '1' }: rsvnNorAndsVnSeqNoProps): Promise<ServerState<listBookingServerApi>> => {
  return request
    .get(`/v1/bookings/${rsvnNo}/${rsvnSeqNo}`, {
      ...headers({}),
    })
    .then((res) => res.data);
};

//CheckIn

export const selectCheckedInBooking = ({ folioNo }: folioNoProps): Promise<ServerState<selectCheckedInBookingServerApi>> => {
  return request
    .get(`/v1/inhouses/${folioNo}`, {
      ...headers({}),
    })
    .then((res) => res.data);
};

//결제 여부 확인
export const confirmCheckInPayment = ({
  rsvnNo,
  rsvnSeqNo = '1',
}: rsvnNorAndsVnSeqNoProps): Promise<ServerState<confirmCheckInPaymentServerApi>> => {
  return request
    .get(`/v1/checkins/${rsvnNo}/${rsvnSeqNo}/payments/yn`, {
      ...headers({}),
      ...querystring({
        langTypeCode,
      }),
    })
    .then((res) => res.data);
};

//예약 고객 미결제 금액 조회
export const confirmCheckInAmount = ({
  rsvnNo,
  rsvnSeqNo = '1',
}: rsvnNorAndsVnSeqNoProps): Promise<ServerState<confirmCheckInAmountServerApi>> => {
  return request
    .get(`/v1/checkins/${rsvnNo}/${rsvnSeqNo}/balance`, {
      ...headers({}),
      ...querystring({
        langTypeCode,
      }),
    })
    .then((res) => res.data);
};

//결제 내역 등록
export const registCheckInPayment = ({
  rsvnNo,
  rsvnSeqNo = '1',
  trnsNo,
  intApprTypeCode,
  intPayStatusCode,
  payFnshDate,
  successYn,
  trnsDate,
  trnsHHMMSS,
  trnsAmt,
  cardMonthCnt,
  cardApprNo,
  cardName,
  cardIssueCode,
  cardPurcCode,
  trnsBankCode,
  acctNo,
  depositor,
  cashRcptTypeCode,
  cashRcptApptNo,
  cashRcptCxlApptNo,
  responseCode,
  message1,
  message2,
  dpsitPayDivCode,
  userId,
  userIp,
  payMgtNo,
  dpsitPayNo,
  outYN,
  outMsg,
}: registCheckInPaymentApiProps): Promise<ServerState<registCheckInPaymentServerApi>> => {
  return request
    .post(
      `/v1/checkins/${rsvnNo}/${rsvnSeqNo}/payments/pg`,
      {
        company_id,
        vender_id,
        rsvnNo,
        rsvnSeqNo,

        trnsNo, // 거래 승인 번호
        intApprTypeCode, // 인터넷 결재 유형 코드 (P)
        intPayStatusCode, // 인터넷 결재 상태 코드 (11)
        payFnshDate, // 인터넷 지불 일자
        successYn,
        trnsDate, // 거래일자 (YYYYMMDD 포멧)
        trnsHHMMSS,
        trnsAmt,
        cardMonthCnt, // 카드 할부 개월 수
        cardApprNo, // 카드 승인 번호
        cardName,
        cardIssueCode, // 카드 발급사 코드
        cardPurcCode, // 카드 매입사 코드
        trnsBankCode, // 거래 은행 코드
        acctNo, // 계좌 번호
        depositor, // 예금주 명
        cashRcptTypeCode, // 현금 영수증 발급 구분 코드
        cashRcptApptNo, // 현금 영수증 승인 번호
        cashRcptCxlApptNo, // 현금 영수증 취소 승인 번호
        responseCode,
        message1,
        message2,
        dpsitPayDivCode, // 선수금 분류 코드
        langTypeCode,

        //여기서 부터는 스웨거에 없는 데이터
        /* userId,
      userIp,
      payMgtNo,
      dpsitPayNo,
      outYN,
      outMsg, */
      },
      headers({})
    )
    .then((res) => res.data);
};

//사용 가능한 객실 목록 조회
export const listAvailableRoom = ({
  rsvnNo,
  rsvnSeqNo = '1',
}: rsvnNorAndsVnSeqNoProps): Promise<ServerState<listAvailableRoomServerApi[]>> => {
  return request
    .get('/v1/checkins/rooms/available', {
      ...headers({}),
      ...querystring({
        rsvnNo,
        rsvnSeqNo,
        langTypeCode,
      }),
    })
    .then((res) => res.data);
};

//체크인 처리
export const checkIn = ({
  rsvnNo,
  rsvnSeqNo = '1',
  roomNo,
  privacyPolicy = 'Y',
}: checkInApiProps): Promise<ServerState<checkInsServerApi>> => {
  return request
    .post(
      '/v1/checkins',
      {
        rsvnNo,
        rsvnSeqNo,
        roomNo,
        privacyPolicy,
        langTypeCode,
      },
      headers({})
    )
    .then((res) => res.data);
};

//퇴실 단계 미결제 금액 조회
export const confirmCheckOutAmount = ({ folioNo }: folioNoProps): Promise<ServerState<confirmCheckOutAmountServerApi>> => {
  return request
    .get(`/v1/checkouts/${folioNo}/payments/balance`, {
      ...headers({}),
      ...querystring({
        langTypeCode,
      }),
    })
    .then((res) => res.data);
};

//퇴실 단계 결제 금액 내역
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const registCheckOutPayment = ({
  trnsNo,
  intApprTypeCode,
  intPayStatusCode,
  payFnshDate,
  successYn,
  trnsDate,
  trnsHHMMSS,
  trnsAmt,
  cardMonthCnt,
  cardApprNo,
  cardName,
  cardIssueCode,
  cardPurcCode,
  trnsBankCode,
  acctNo,
  depositor,
  cashRcptTypeCode,
  cashRcptApptNo,
  cashRcptCxlApptNo,
  responseCode,
  message1,
  message2,
  folioNo,
  userId,
  userIp,
  dpsitPayDivCode,
  payMgtNo,
  dpsitPayNo,
  outYN,
  outMsg,
}: registCheckOutPaymentApiProps): Promise<ServerState<registCheckOutPaymentServerApi>> => {
  return request
    .post(
      `/v1/checkouts/${folioNo}/payments/pg`,
      {
        company_id,
        vender_id,
        folioNo,

        trnsNo, // 거래 승인 번호
        intApprTypeCode, // 인터넷 결재 유형 코드 (P)
        intPayStatusCode, // 인터넷 결재 상태 코드 (11)
        payFnshDate, // 인터넷 지불 일자
        successYn,
        trnsDate, // 거래일자 (YYYYMMDD 포멧)
        trnsHHMMSS,
        trnsAmt,
        cardMonthCnt, // 카드 할부 개월 수
        cardApprNo, // 카드 승인 번호
        cardName,
        cardIssueCode, // 카드 발급사 코드
        cardPurcCode, // 카드 매입사 코드
        trnsBankCode, // 거래 은행 코드
        acctNo, // 계좌 번호
        depositor, // 예금주 명
        cashRcptTypeCode, // 현금 영수증 발급 구분 코드
        cashRcptApptNo, // 현금 영수증 승인 번호
        cashRcptCxlApptNo, // 현금 영수증 취소 승인 번호
        responseCode,
        message1,
        message2,
        dpsitPayDivCode, // 선수금 분류 코드
        langTypeCode,

        //여기서 부터는 스웨거에 없는 데이터
        /* userId,
      userIp,
      payMgtNo,
      dpsitPayNo,
      outYN,
      outMsg, */
      },
      headers({})
    )
    .then((res) => res.data);
};

//체크아웃 처리
export const checkOut = ({ folioNo, earlyCheckoutYN }: checkOutApiPops): Promise<ServerState<any>> => {
  return request
    .post(
      '/v1/checkouts',
      {
        folioNo,
        earlyCheckoutYN,
        langTypeCode,
      },
      headers({})
    )
    .then((res) => res.data);
};

export const config = (): Promise<ServerState<configDataApi>> => {
  const bsnsCode = 15;

  return request
    .get('/v1/configs/paymentPolicy', {
      ...headers({}),
      ...querystring({
        bsnsCode,
        langTypeCode,
      }),
    })
    .then((res) => res.data);
};

export const checkInAdvancePayment = ({
  folioNo,
  trnsNo,
  intApprTypeCode,
  intPayStatusCode,
  payFnshDate,
  successYn,
  trnsDate,
  trnsHHMMSS,
  trnsAmt,
  cardMonthCnt,
  cardApprNo,
  cardName,
  cardIssueCode,
  cardPurcCode,
  trnsBankCode,
  acctNo,
  depositor,
  cashRcptTypeCode,
  cashRcptApptNo,
  cashRcptCxlApptNo,
  responseCode,
  message1,
  message2,
}: registCheckOutPaymentApiProps): Promise<ServerState<registCheckOutPaymentServerApi>> => {
  return request
    .post(
      `/v1/checkins/${folioNo}/payments/pg`,
      {
        folioNo,
        vender_id,
        company_id,

        trnsNo, // 거래 승인 번호
        intApprTypeCode, // 인터넷 결재 유형 코드 (P)
        intPayStatusCode, // 인터넷 결재 상태 코드 (11)
        payFnshDate, // 인터넷 지불 일자
        successYn,
        trnsDate, // 거래일자 (YYYYMMDD 포멧)
        trnsHHMMSS,
        trnsAmt,
        cardMonthCnt, // 카드 할부 개월 수
        cardApprNo, // 카드 승인 번호
        cardName,
        cardIssueCode, // 카드 발급사 코드
        cardPurcCode, // 카드 매입사 코드
        trnsBankCode, // 거래 은행 코드
        acctNo, // 계좌 번호
        depositor, // 예금주 명
        cashRcptTypeCode, // 현금 영수증 발급 구분 코드
        cashRcptApptNo, // 현금 영수증 승인 번호
        cashRcptCxlApptNo, // 현금 영수증 취소 승인 번호
        responseCode,
        message1,
        message2,
        langTypeCode,
      },
      headers({})
    )
    .then((res) => res.data);
};

export const checkInDeposit = ({
  rsvnNo,
  rsvnSeqNo = '1',
  trnsNo,
  intApprTypeCode,
  intPayStatusCode,
  payFnshDate,
  successYn,
  trnsDate,
  trnsHHMMSS,
  trnsAmt,
  cardMonthCnt,
  cardApprNo,
  cardName,
  cardIssueCode,
  cardPurcCode,
  trnsBankCode,
  acctNo,
  depositor,
  cashRcptTypeCode,
  cashRcptApptNo,
  cashRcptCxlApptNo,
  responseCode,
  message1,
  message2,
  dpsitPayDivCode,
}: registCheckInPaymentApiProps): Promise<ServerState<registCheckInPaymentServerApi>> => {
  return request
    .post(
      `/v1/checkins/${rsvnNo}/${rsvnSeqNo}/deposit/pg`,
      {
        rsvnNo,
        rsvnSeqNo,
        vender_id,
        company_id,

        trnsNo, // 거래 승인 번호
        intApprTypeCode, // 인터넷 결재 유형 코드 (P)
        intPayStatusCode, // 인터넷 결재 상태 코드 (11)
        payFnshDate, // 인터넷 지불 일자
        successYn,
        trnsDate, // 거래일자 (YYYYMMDD 포멧)
        trnsHHMMSS,
        trnsAmt,
        cardMonthCnt, // 카드 할부 개월 수
        cardApprNo, // 카드 승인 번호
        cardName,
        cardIssueCode, // 카드 발급사 코드
        cardPurcCode, // 카드 매입사 코드
        trnsBankCode, // 거래 은행 코드
        acctNo, // 계좌 번호
        depositor, // 예금주 명
        cashRcptTypeCode, // 현금 영수증 발급 구분 코드
        cashRcptApptNo, // 현금 영수증 승인 번호
        cashRcptCxlApptNo, // 현금 영수증 취소 승인 번호
        responseCode,
        message1,
        message2,
        dpsitPayDivCode, // 선수금 분류 코드
        langTypeCode,
      },
      headers({})
    )
    .then((res) => res.data);
};
